import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import iPad01 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(1).webp'
import iPad02 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(2).webp'
import iPad03 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(3).webp'
import iPad04 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(4).webp'
import iPad05 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(5).webp'
import iPad06 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(6).webp'
import iPad07 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(7).webp'
import iPad08 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(8).webp'
import iPad09 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(9).webp'
import iPad10 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(10).webp'
import iPad11 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(11).webp'
import iPad12 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(12).webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Go to the Multi View or Cameras menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select New IP Camera to add a new IP camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Either scan the QR Code from the web interface of your camera or select the camera model manually."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select the manufacturer of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select your camera model. Then click on Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter a name for your camera and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the DDNS address of your camera or the local IP address and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Type in your cameras LAN (HTTP or HTTPS) port Ihrer Kamera ein und klicken Sie auf Weiter um fortzufahren."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter your camera login username and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad10} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter your camera login password and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad11} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="You can click the green + to add a local IP address and the local port of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad12} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Now you can check your settings and click Save to add your camera."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function DDNSGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default DDNSGallery