import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DDNSTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "Feature" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { featureColumn: 'DDNS or P2P', descriptionColumn: '[2] First you have to choose to add your camera via its local IP / DDNS (Internet) address or its P2P UID. For the Point-2-Point Service please Continue Reading in the P2P Section.' },
        { featureColumn: 'Camera Model', descriptionColumn: '[3] InstarVision allows you to add cameras from different manufacturers beside INSTAR. Please choose the correct brand and model of your camera. You can also opt to simply scan your camera`s QRcode from the DDNS menu - it will automatically fill out all the necessary information to add your camera - all the following steps will be filled out already!' },
        { featureColumn: 'Camera Name', descriptionColumn: '[7] You can choose a name for your camera - the name does not have to be identical with the camera name inside the web user interface. But every camera you add has to have a unique name.' },
        { featureColumn: 'Host / IP Address', descriptionColumn: '[8] When your phone and your camera are both on the same network it is recommended to use the Search Function to add your camera&apos;s address automatically. Otherwise type your camera&apos;s local IP address (e.g. 192.168.178.26) to access your camera at home or use your DDNS address (e.g. abcd12.ddns-instar.de), when you want to be able to access your camera over the internet. Please be aware that you will need a Port Forwarding rule inside your internet router to use the DDNS Service!' },
        { featureColumn: 'Port', descriptionColumn: '[11] Type in the camera&apos;s http or https port, that can be found and set in the camera&apos;s web user interface. The default value is 80 - but we recommend that you change it to the port 8081 or higher inside your camera&apos;s webUI. Please be aware that you will need a Port Forwarding rule inside your internet router to use the DDNS Service!' },
        { featureColumn: 'User Name', descriptionColumn: '[12] To access your camera you will need to provide the admin username you use to log in to your camera`s web user interface. The default username for all FHD, HD and VGA cameras is admin' },
        { featureColumn: 'Password', descriptionColumn: '[13] Provide the password you use to log in to your camera&apos;s web user interface. The default password is instar for HD cameras and no password for VGA cameras. You were asked to change the default password during the initial installation in case of FHD cameras.' },
        { featureColumn: 'Test/Add', descriptionColumn: '[14] Afterwards click on Test to check your configuration. Click on Save - Diskette Symbol in the top right - once the test was successful. Go to the Multiview Menu to access your cameras live video stream.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DDNSTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DDNSTable)