import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'DDNS',
      href: '/en/Software/iOS/InstarVision/iPad/DDNS/',
    },
    {
      text: 'Multiview',
      href: '/en/Software/iOS/InstarVision/iPad/Multiview/',
    },
    {
      text: 'P2P',
      href: '/en/Software/iOS/InstarVision/iPad/P2P/',
    },
    {
      text: 'Recording',
      href: '/en/Software/iOS/InstarVision/iPad/Recording/',
    },
    {
      text: 'Settings',
      href: '/en/Software/iOS/InstarVision/iPad/Settings/',
    },
    {
      text: '◄ iPad',
      href: '/en/Software/iOS/InstarVision/iPad/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}